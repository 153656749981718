.landing-page {
    padding-top: 3em;

    .app-icon {
        width: 100px;
        border-radius: 15px;
    }

    .gameplay-gif {
        max-width: 300px;
        margin: auto;
        border-radius: 15px;

        .video-react {
            background-color: transparent;
            border-radius: 15px;

            .video-react-control-bar {
                border-radius: 0 0 15px 15px;
            }
        }

        img, video, .video-react-poster {
            width: 100%;
            border-radius: 15px;
        }
    }

    .game-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1em;

        .short-description {
            white-space: break-spaces;
        }
    }

    .store-link {
        max-width: 220px;
        margin: auto;
    }

    .coming-soon-message {
        text-align: center;

        h3 {
            font-family: 'Fredoka One';
        }
    }

    .screenshots {
        margin-top: 6em;

        .screenshot {
            width: 100%;
            border-radius: 15px;
            margin-bottom: 3em;
        }
    }

    footer {
        padding-top: 2em;
        padding-bottom: 4em;

        .mail-list-form {
            max-width: 500px;
            margin: auto;
        }

        .extra-links {
            text-align: center;
            list-style: none;
            padding: 0;
        }
    }
}

@media (max-width: 930px) {
    .container, .container-sm {
        &.landing-page {
            max-width: 700px;
        }
    }
}