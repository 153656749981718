.mail-list-form {
    .form-label {
        text-align: center;
        font-family: 'Fredoka One';
        width: 100%;
        font-size: 1.5em;
    }

    .submit-button {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }

    .send-status {
        text-align: center;

        &.success {
            color: green;
        }

        &.sending {
            color: rgb(221, 113, 41);
        }
    }
}