.content {
    .game-details {
        margin-top: 1em;
        transition: all .3s;

        img {
            width: 100%;
            margin: auto;
            border-radius: 25%;
            box-shadow: 3px 3px 7px grey;
        }

        h4 {
            text-align: center;
        }

        &:hover {
            transform: scale(1.06);
        }
    }
}