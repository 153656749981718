.content {
    margin-top: 0em;
    margin-bottom: .5em;

    .nav {
        margin: 0;
    }

    .content-category {
        margin-top: 2.5em;

        h6 {
            font-family: 'Fredoka One';
        }
    }
}