#Eye, #Eye1 {
  transition: .07s;
}

#Iris {
  transform: translate(0px, 0px);
}

#LeftBrow, #RightBrow {
  transition: .3s;
}

.face-wrapper {
  max-width: 350px;
  width: 111%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.non-mobile .face-wrapper {
  margin: 0;
  max-width: none;
  padding-top: inherit;
  transform: translate(80px, 0px);
}

#Face {
  &.blink {
    #Eye, #Eye1 {
      d: path("M763.78,537.889C763.908,538.018 772.769,549.997 772.769,549.997C772.995,549.997 796.936,545.187 797.162,545.187L772.769,549.997C772.769,549.997 763.651,537.761 763.78,537.889Z");
    }
  }

  &:hover {
    #LeftBrow {
      d: path("M755.991,481.447C773.086,482.586 829.65,532.49 828.291,535.207C826.933,537.924 765.322,500.127 756.006,502.068C746.69,504.009 720.64,520.998 717.729,519.251C714.818,517.504 710.485,506.871 713.202,500.66C715.919,494.45 741.435,480.476 755.991,481.447Z");
      transform: matrix(0.992546,0.121869,-0.121869,0.992546,71.4172,-98.9558);
    }

    #RightBrow {
      transform: translate(5px, -20px);
    }
  }
}