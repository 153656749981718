.blog-overview .card {
    margin-bottom: 40px;
    transition: transform .3s;
    
    &:hover {
        transform: scale(1.05);
    }
}

.nav {
    margin-bottom: 2em;

    .nav-link.active {
        background-color: #5b2333;
    }
}