h1 {
    font-family: 'Fredoka One';
    font-size: 2.5em;
    margin: 0;
}

@media (min-width: 1200px) {
  h1 {
      font-size: 4em;
  }
}