.latest-image {
    width: 100%;
    border-radius: 10px;
}

.stats-table {
    margin: auto;
}

h1 {
    font-size: 3.5em;
}

.progress {
    height: 25px;
    background-color: #ffd7d7;
}
