.card {
    border: none;
    border-radius: 5px;
    overflow: hidden
}

.inner-card {
    height: 20px;
    background: linear-gradient(270deg, #fc9a9a, #5b2333);
    background-size: 400% 400%;
}

.card-1 {
    height: 200px;
    background: linear-gradient(270deg, #fc9a9a, #5b2333);
    background-size: 400% 400%;
}

.card-2 {
    height: 130px
}

.h-screen {
    height: 100vh
}

.animate-pulse {
    animation: pulse 4s cubic-bezier(.4, 0, .6, 1) infinite
}

@keyframes pulse {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}