@import '~video-react/styles/scss/video-react';

$theme-colors: (
  "primary": #ff0c00,
  "secondary": #5b2333
);

$body-bg: #fcf1ec;
$body-color: #5b2333;

$input-border-color: #938484;

@import "~bootstrap/scss/bootstrap";

body {
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: #5b2333;
  color: white;
}
