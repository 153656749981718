.post-content {
    margin: auto;
    margin-bottom: 3em;
    margin-top: 2.3em;

    .card {
        overflow: visible;

        .card-title {
            margin-top: .5em;
            margin-bottom: 0;
        }

        .post-gist {
            margin: 3em 0;
            text-align: right;
        }

        .post-text {
            margin: auto;
            max-width: 550px;

            > * {
                text-align: justify;
            }
        }

        .post-cta {
            margin-top: 2em;
            text-align: center;

            .post-cta-button {
                margin-top: 1em;
            }
        }
    }

    blockquote {
        font: 14px/22px normal helvetica, sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 50px;
        padding-left: 15px;
        border-left: 3px solid #ccc;
    }
}

.blog-post {
    .mail-list-form {
        padding-top: 3em;
        padding-bottom: 4em;
        max-width: 500px;
        margin: auto;
    }
}

@media (min-width: 1200px) {
    .post-content {
        width: 80%;
    }
}

@media (min-width: 600px) {
    .card-img-top {
        transform: scale(1.06);
        border-radius: 10px;
    }
}