
.App {
  margin-top: .5rem;
  margin-bottom: 5em;

  &.non-mobile {
    margin-top: 3rem;
  }
}

@media (max-width: 930px) {
  .container, .container-sm {
    max-width: 570px;
  }
}