.share-icons {
    margin-top: .8em;

    .col {
        flex-grow: unset;
    }

    .share-icon {
        margin-top: .5em;
    }
}